import React from 'react';
import Layout from '../components/shared/Layout';
import SEO from '../components/shared/SEO';
import {useTranslation} from 'react-i18next';
import css from './pricing.module.scss';
import Footer from "../components/shared/Footer/Footer";
import OptiplusPricing2 from "../components/shared/optiplusPricing/OptiplusPricing2";
import { getRoutes } from '../components/shared/routes';

const PricingPage = () => {
  const { t } = useTranslation();
  const pageTitle = `${getRoutes().Pricing.pageTitle}`;


  return (
    <Layout>
      <SEO title={pageTitle} description={'Simple and transparent pricing'}/>

      <div className={css.wrapper}>

        <div className={css.OptiplusPricingHero}>
          <OptiplusPricing2 section={'hero'} />
        </div>

        <div className={css.Footer}>
          <Footer/>
        </div>

      </div>
    </Layout>
  );
};

export default PricingPage;
