import React from "react";

import css from './optiplusPricingBox1.module.scss';
import CheckIcon from "../../../images/common/optiplusPricing/check_subscription.svg";
import PayAsYouGoImage from "../../../images/common/optiplusPricing/payasyougo.svg";
import AnnualSubscriptionImage from "../../../images/common/optiplusPricing/annualsubscription.svg";
import CompanySalesMail from "../CompanySalesMail/CompanySalesMail";
import MainButton from "../MainButton/MainButton";

const OptiplusPricingBox1 = props => {
  const { version, startButton } = props;
  const versions = {
    payAsYouGo: "payAsYouGo",
    annualSubscription: "annualSubscription"
  };

  const currency = {
    euro: <>&#8364;</>,
    dolar: <>&#36;</>,
    pound: <>&#163;</>
  };

  const renderCheckbox = () => {
    if (version === versions.annualSubscription) {
      return (
        <div className={css.check}>
            <img src={CheckIcon} alt=""/>
        </div>
      )
    }
    return null;
  };

  const renderImage = () => {
    switch (version) {
      case versions.payAsYouGo: {
        return (
          <div className={css.mainImage}>
            <img src={PayAsYouGoImage} alt=""/>
          </div>
        )
      }
      case versions.annualSubscription: {
        return (
          <div className={css.mainImage}>
            <img src={AnnualSubscriptionImage} alt=""/>
          </div>
        )
      }
      default: {
        return null;
      }
    }
  };

  const renderText = () => {
    switch (version) {
      case versions.payAsYouGo: {
        return (
          <>
            <div className={css.title}>
              Pay as you go
            </div>
            <div className={css.text1}>
              <br/>One credit equals <strong>100MB</strong>, down payment.
            </div>
            <div className={css.priceWrapper}>
              <div className={css.price}><span>23.90{currency.dolar}</span><span className={css.asterisk}>*</span></div>
              <div className={css.slash}>/</div>
              <div className={css.price}><span>19.90{currency.euro}</span><span className={css.asterisk}>*</span></div>
              <div className={css.slash}>/</div>
              <div className={css.price}><span>17.90{currency.pound}</span><span className={css.asterisk}>*</span></div>
              <div className={css.unit}>
                per<br/>100MB
              </div>
            </div>
            <div className={css.text2}>
              The usage is calculated in GB <br/>of photo processing transfers <br/>from an upload to output download.<br/><br/>
            </div>
          </>
        )
      }
      case versions.annualSubscription: {
        return (
          <>
            <div className={css.title}>
              Annual subscription
            </div>
            <div className={css.text1}>
                <p>software license</p><br/>
              Monthly <strong>flat fee</strong> of
            </div>
            <div className={css.priceWrapper}>
              <div className={css.price}><span>49{currency.dolar}</span><span className={css.asterisk}>*</span></div>
              <div className={css.slash}>/</div>
              <div className={css.price}><span>39{currency.euro}</span><span className={css.asterisk}>*</span></div>
              <div className={css.slash}>/</div>
              <div className={css.price}><span>35{currency.pound}</span><span className={css.asterisk}>*</span></div>
            </div>
            <div className={css.text3}>
              Includes <strong>500MB</strong> of photo processing transfers <br/>from an upload to output download. 
              {/* <br/><strong>Additionally</strong>, AWS cloud processing<br/> real cost billing recharge. */}
              <p className={css.p}>
                <strong>Additionally</strong>, AWS cloud processing<br/> real cost billing recharge.
              </p>
            </div>
             
            
          </>
        )
      }
      default: {
        return null;
      }
    }
  };

  const getMailTitle = () => {
    switch (version) {
      case versions.payAsYouGo: {
        return "Optiplus Pricing: Pay As You Go"
      }
      case versions.annualSubscription: {
        return "Optiplus Pricing: Annual Subscription"
      }
      default: {
        return "";
      }
    }
  };

  return (
    <div className={css.wrapper}>
      {renderCheckbox()}
      <div className={css.content}>
        {renderImage()}
        {renderText()}
          {startButton &&
          <CompanySalesMail
              contentToDisplay={
                  <MainButton
                      label= "Start now!"
                      isLink={false}
                      filled
                      color={'green'}
                  />
              }
              subject={getMailTitle()}
          />
          }
      </div>
    </div>
  );
};

export default OptiplusPricingBox1;
