import React from "react";
import { useTranslation} from 'react-i18next';
import css from './optiplusPricing2.module.scss';
import BoxHeader from "../BoxHeader/BoxHeader";
import OptiplusPricingBox1 from "./OptiplusPricingBox1";

const OptiplusPricing2 = () => {
  return (
    <div className={css.wrapper}>
      <div className={css.title}>
        <BoxHeader lineColor={'green'} headerType={'h1'} contentAligned={false} bigger>
            Pricing
        </BoxHeader>
      </div>
      <div className={css.text}>Full set of functionalities for use in both schemes.</div>
      <div className={css.boxes}>
        <OptiplusPricingBox1
            version={"payAsYouGo"}
            startButton={true}
        />
        <OptiplusPricingBox1
            version={"annualSubscription"}
            startButton={true}
        />
      </div>
      <div className={css.text}>Focus on the core of your business and enlists the help to OptiwAI.</div>
      <div className={css.priceAsteriskDescription}>*Prices quoted do not include any sales taxes or other taxes measured by sales imposed by a country, state, city governments or subdivisions thereof.</div>
    </div>
  );
};

export default OptiplusPricing2;
